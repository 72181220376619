import React, { useState } from 'react'
import Carousel from 'react-bootstrap/Carousel'

const TestimonialCarousel = ({ testimonials }) => {
  const [index, setIndex] = useState(0)
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }

  const items = testimonials.map(data => {
    const {
      id,
      name,
      organisation,
      profileImage,
      quote: { quote }
    } = data.node

    const imgQuery = '?w=100&h=100&fit=thumb&f=faces&r=50'
    const urlImage = profileImage && 'file' in profileImage ? profileImage.file.url + imgQuery : ''

    return (
      <Carousel.Item key={id}>
        <div style={{ textAlign: 'center', padding: '30px' }}>
          <img src={urlImage} alt={organisation} />
          <h3 style={{ margin: '5px 0 5px 0', color: '#555' }}>{name}</h3>
          <h6>{organisation}</h6>
          <p style={{ fontSize: '12pt', padding: '0 10% 0 10%' }}>{quote}</p>
        </div>
        <Carousel.Caption />
      </Carousel.Item>
    )
  })

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      interval={5000}
      style={{ backgroundColor: '#fff', minHeight: '400px' }}
    >
      {items}
    </Carousel>
  )
}

export default TestimonialCarousel
