import React from 'react'
import './testimonial.css'
import { getContentfulUrl, rightBack } from '../../shared/utilities'

const Testimonial = ({ data }) => {
  const {
    name,
    organisation,
    jobTitle,
    profileImage,
    quote: { quote }
  } = data

  const imgQuery = '?w=200&h=200&fit=thumb&f=faces&r=100'
  const urlImage = getContentfulUrl(profileImage) + imgQuery

  return (
    <section className="Testimonial">
      <div className="Testimonial-img">
        <img src={urlImage} alt={organisation} width="200px" />
      </div>
      <div className="Testimonial-information">
        <h3 className="quote-author">{name}</h3>
        <h5 className="quote-author-title">{jobTitle}</h5>
        <h5 className="quote-org">{organisation}</h5>
        <blockquote>{quote}</blockquote>
      </div>
    </section>
  )
}

export default Testimonial
