import React, { useState } from 'react'
import { Link } from 'gatsby'
import '../styles/comparisonTable.css'

const ComparisonTable = () => {
  const [category, setCategory] = useState(1)

  const handleClick = value => {
    setCategory(value)
  }

  const active1 = category === 1 ? 'active ' : ''
  const active2 = category === 2 ? 'active ' : ''

  const style1 = { display: category === 1 ? 'table-cell' : 'none' }
  const style2 = { display: category === 2 ? 'table-cell' : 'none' }

  return (
    <div id="comparison-membership" className="container comparison-content">
      <div className="comparison-tab">
        <article>
          <ul>
            <li className={`${active1}bg-purple`}>
              <button onClick={() => handleClick(1)} type="button">
                Members
              </button>
            </li>
            <li className={`${active2}bg-blue`}>
              <button onClick={() => handleClick(2)} type="button">
                Associates
              </button>
            </li>
          </ul>

          <table>
            <thead>
              <tr>
                <th className="hide"> </th>
                <th className="bg-purple">Members</th>
                <th className="bg-blue">Associates</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="bg-purple">
                  <strong>Fees</strong>
                </td>
                <td className="bg-blue">
                  <Link to="/membership/fees">See Fees</Link>
                </td>
                <td className="bg-blue">
                  <Link to="/membership/fees">See Fees</Link>
                </td>
              </tr>

              <tr className="compare-row-table">
                <td>Reduced registration fees for MI events</td>
                <td style={style1}>
                  <span className="tick">&#10004;</span>
                </td>
                <td style={style2}>
                  <span className="tick">&#10004;</span>
                </td>
              </tr>
              <tr>
                <td>
                  Access to{' '}
                  <a href="https://missionsinterlink.wildapricot.org/">members only resources</a>
                </td>
                <td style={{ display: category === 1 ? 'table-cell' : 'none' }}>
                  <span className="tick">&#10004;</span>
                </td>
                <td style={style2}>
                  <span className="tick">&#10004;</span>
                </td>
              </tr>
              <tr className="compare-row-table">
                <td>Access to members only events</td>
                <td style={{ display: category === 1 ? 'table-cell' : 'none' }}>
                  <span className="tick">&#10004;</span>
                </td>
                <td style={style2}>
                  <span className="tick">&#10004;</span>
                </td>
              </tr>
              <tr>
                <td>
                  Priority access and special fees to{' '}
                  <a href="https://missionsinterlink.org.au/resources/accommodation/aldinga-beach">
                    Aldinga Beach Retreat
                  </a>
                </td>
                <td style={{ display: category === 1 ? 'table-cell' : 'none' }}>
                  <span className="tick">&#10004;</span>
                </td>
                <td style={style2}>
                  <span className="tick">&#10004;</span>
                </td>
              </tr>
              <tr className="compare-row-table">
                <td>
                  {' '}
                  Special MI membership rates for holiday and{' '}
                  <a href="https://missionsinterlink.org.au/resources/accommodation">
                    retreat accommodations
                  </a>
                </td>
                <td style={{ display: category === 1 ? 'table-cell' : 'none' }}>
                  <span className="tick">&#10004;</span>
                </td>
                <td style={style2}>
                  <span className="tick">&#10004;</span>
                </td>
              </tr>
              <tr>
                <td>Insurance discounts (EA Mission care)</td>
                <td style={{ display: category === 1 ? 'table-cell' : 'none' }}>
                  <span className="tick">&#10004;</span>
                </td>
                <td style={style2}>
                  <span className="tick">&#10004;</span>
                </td>
              </tr>
              <tr className="compare-row-table">
                <td>Travel insurance (EA insurance, discounted)</td>
                <td style={{ display: category === 1 ? 'table-cell' : 'none' }}>
                  <span className="tick">&#10004;</span>
                </td>
                <td style={style2}>
                  <span className="tick">&#10004;</span>
                </td>
              </tr>
              <tr>
                <td>Group travel discount (EA insurance, discounted)</td>
                <td style={{ display: category === 1 ? 'table-cell' : 'none' }}>
                  <span className="tick">&#10004;</span>
                </td>
                <td style={style2}>
                  <span className="tickred">✘</span>
                </td>
              </tr>
              <tr className="compare-row-table">
                <td>Members only newsletter (NFP issues, governance, etc)</td>
                <td style={{ display: category === 1 ? 'table-cell' : 'none' }}>
                  <span className="tick">&#10004;</span>
                </td>
                <td style={style2}>
                  <span className="tick">&#10004;</span>
                </td>
              </tr>
              <tr>
                <td>
                  MissionSeek free access to unlimited posting of vacancies, events and mission
                  opportunities.
                </td>
                <td style={{ display: category === 1 ? 'table-cell' : 'none' }}>
                  <span className="tick">&#10004;</span>
                </td>
                <td style={style2}>
                  <span className="tick">&#10004;</span>
                </td>
              </tr>
              <tr className="compare-row-table">
                <td>GiveHigher free access to unlimited posting of projects for fundrising</td>
                <td style={{ display: category === 1 ? 'table-cell' : 'none' }}>
                  <span className="tick">&#10004;</span>
                </td>
                <td style={style2}>
                  <span className="tick">&#10004;</span>
                </td>
              </tr>
              <tr>
                <td>
                  Opportunities to display services and training at the MI conferences and events
                  (when applicable)
                </td>
                <td style={{ display: category === 1 ? 'table-cell' : 'none' }}>
                  <span className="tick">&#10004;</span>
                </td>
                <td style={style2}>
                  <span className="tick">&#10004;</span>
                </td>
              </tr>
              <tr className="compare-row-table">
                <td>
                  Voting right at the annual meetings, being nominated and elected for the National
                  Leadership Team
                </td>
                <td style={{ display: category === 1 ? 'table-cell' : 'none' }}>
                  <span className="tick">&#10004;</span>
                </td>
                <td style={style2}>
                  <span className="tick">&#10004;</span>
                </td>
              </tr>
              <tr>
                <td>Use of MI Member/Associate logo on the website and publishing materials</td>
                <td style={{ display: category === 1 ? 'table-cell' : 'none' }}>
                  <span className="tick">&#10004;</span>
                </td>
                <td style={style2}>
                  <span className="tick">&#10004;</span>
                </td>
              </tr>
              <tr className="compare-row-table">
                <td>
                  Exemption from Australian income tax for organisations and personnel serving
                  overseas, through recognition by the ATO as a member of a prescribed
                  institution.&nbsp;
                  <a href="https://assets.ctfassets.net/ra0r88kvhzmj/5v8oqMcPRK74C29ysmPRj0/2648ba44f4f669ea83baf54722f19ddb/Tax_exemption_for_charities.pdf">
                    More info here.
                  </a>
                </td>
                <td style={{ display: category === 1 ? 'table-cell' : 'none' }}>
                  <span className="tick">&#10004;</span>
                </td>
                <td style={style2}>
                  <span className="tickred">✘</span>
                </td>
              </tr>
            </tbody>
          </table>
        </article>
      </div>
    </div>
  )
}

export default ComparisonTable
