import React from 'react'

const AssetHyperlink = ({ node }) => {
  const { fields } = node.data.target

  let title
  if ('content' in node) {
    title = node.content[0].value
  } else if (fields && 'title' in fields) {
    title = fields.title['en-US']
  } else {
    title = 'Click to Open'
  }

  if (fields && 'file' in fields) {
    const { url } = fields.file['en-US']
    return <a href={url}>{title}</a>
  }
  return 'AssetHyperlink'
}

export default AssetHyperlink
