import React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import BlockEmbeddedEntry from '../components/Blocks/BlockEmbeddedEntry'
import EmbeddedAsset from '../components/Blocks/EmbeddedAsset'
import Vimeo from '../components/Blocks/Vimeo'
import YouTube from '../components/Blocks/YouTube'
import AssetHyperlink from './assetHyperlink'
import Hyperlink from './Hyperlink'
import { getSafe } from './utilities'

const VIMEO_URL = 'https://vimeo.com/'
const YOUTUBE_URL = 'youtu'

export default {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const { sys } = node.data.target
      if (sys && 'contentType' in sys && 'sys' in sys.contentType) {
        const blockID = sys.contentType.sys.id
        return <BlockEmbeddedEntry fields={node.data.target.fields} blockID={blockID} />
      }
      return 'embedded entry'
    },
    'asset-hyperlink': node => {
      const renderedHTML = <AssetHyperlink node={node} />
      return renderedHTML
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const renderedHTML = <EmbeddedAsset fields={node.data.target.fields} />
      return renderedHTML
    },
    'embedded-entry-inline': node => {
      const { sys } = node.data.target
      if (sys && 'contentType' in sys && 'sys' in sys.contentType) {
        const blockID = sys.contentType.sys.id
        const renderedHTML = (
          <BlockEmbeddedEntry fields={node.data.target.fields} blockID={blockID} />
        )
        return renderedHTML
      }
      return 'embedded-entry-inline'
    },
    hyperlink: node => {
      const url = node.data.uri
      if (url.includes(YOUTUBE_URL)) return <YouTube url={url} title={node.content[0].value} />
      if (url.includes(VIMEO_URL)) return <Vimeo url={url} title={node.content[0].value} />

      const text = getSafe(() => node.content[0].value)
      const anchor = <Hyperlink url={url} text={text} />
      return anchor
    }
  }
}
