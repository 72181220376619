import React from 'react'
import PropTypes from 'prop-types'
import BlockBackgroundColour from './BlockBackgroundColour'
import BlockImageRow from './BlockImageRow'
import BlockImageTextRow from './BlockImageTextRow'
import BlockReactComponent from './BlockReactComponent'
import BlockTitleImageTextCards from './BlockTitleImageTextCards'
import BlockButton from './BlockButton'

const BlockEmbeddedEntry = ({ fields, blockID }) => {
  switch (blockID) {
    case 'blockBackgroundColour':
      return <BlockBackgroundColour fields={fields} />

    case 'blockImageRow':
      return <BlockImageRow fields={fields} />

    case 'blockImageTextRow':
      return <BlockImageTextRow fields={fields} />

    case 'blockTitleImageTextCards':
      return <BlockTitleImageTextCards fields={fields} />

    case 'blockReactComponent':
      return <BlockReactComponent fields={fields} />

    case 'blockButton':
      return <BlockButton fields={fields} />

    default:
      console.log(`Cannot find Block: ${blockID}`)
      return <p>Cannot render Block: {blockID}</p>
  }
}

BlockEmbeddedEntry.propTypes = {}

export default BlockEmbeddedEntry
