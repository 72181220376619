import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { getFieldValue, getSafe } from '../../shared/utilities'
import embeddedRichTextOptions from '../../shared/embeddedRichTextOptions'

const BlockTitleImageTextCards = ({ fields }) => {
  const ENTRY_COUNT = 4
  const userImageQuery = getFieldValue(fields, 'imageQuery')
  const imgQuery = userImageQuery || '?w=200&h=200&fit=thumb'

  let cards = Array(ENTRY_COUNT)
  for (let i = 0; i <= ENTRY_COUNT; i++) {
    const iStr = i === 0 ? '' : i.toString()

    cards[i] = {
      id: i.toString(),
      title: getFieldValue(fields, `title${iStr}`),
      imgUrl: getSafe(() => fields[`image${iStr}`]['en-US'].fields.file['en-US'].url) + imgQuery,
      body: getSafe(() => getFieldValue(fields, `body${iStr}`)),
      text: getFieldValue(fields, 'text')
    }
  }

  cards = cards.filter(item => item.title !== null)

  return (
    <div className="container-wide items-container">
      {cards.map(item => (
        <div
          key={item.id}
          className="event-item"
          style={{ boxShadow: 'none', border: '1px solid #ddd', marginRight: '10px' }}
        >
          <div className="event event-item-body" style={{ width: '230px' }}>
            <h2 className="event-item-body-title">{item.title}</h2>
            <div className="event-item-header">
              {item.imgUrl && <img src={item.imgUrl} alt={item.title} />}
            </div>
            <div className="event-item-body-promotionLine">
              {documentToReactComponents(item.body, embeddedRichTextOptions)}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default BlockTitleImageTextCards
