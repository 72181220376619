import React from 'react'
import { getFieldValue } from '../../shared/utilities'

const EmbeddedAsset = ({ fields }) => {
  const file = getFieldValue(fields, 'file')
  const contentType = file && 'contentType' in file ? file.contentType : ''
  const url = file && 'url' in file ? file.url : ''
  const title = getFieldValue(fields, 'title')

  if (contentType === 'application/pdf') return <a href={url}>{title}</a>
  return <img className="img-content" alt={title} src={url} />
}

export default EmbeddedAsset
