import React from 'react'

function getVideoID(url, title) {
  const VIMEO_URL = 'https://vimeo.com/'
  if (title !== url) return ''
  return url.includes(VIMEO_URL) ? url.slice(VIMEO_URL.length, url.length) : ''
}

const Vimeo = ({ url, title }) => {
  const videoID = getVideoID(url, title)
  const urlEmbed = `https://player.vimeo.com/video/${videoID}`

  if (videoID === '' || Number.isNaN(videoID)) return <a href={url}>{title}</a>

  return (
    <figure className="video">
      <iframe
        title={videoID}
        src={urlEmbed}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
    </figure>
  )
}

export default Vimeo
