import React from 'react'

const Hyperlink = ({ url, text }) => {
  const urlLower = url.toLowerCase()

  if (urlLower.startsWith('http') && !urlLower.includes('missionsinterlink.org.au')) {
    return (
      <a href={url} target="_blank" rel="noreferrer">
        {text}
      </a>
    )
  }
  return <a href={url}>{text}</a>
}

export default Hyperlink
