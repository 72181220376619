import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import BlockImageRow from './BlockImageRow'
import embeddedRichTextOptions from '../../shared/embeddedRichTextOptions'
import { getFieldValue } from '../../shared/utilities'

const BlockBackgroundColour = ({ fields }) => {
  const title = getFieldValue(fields, 'title')
  const body = getFieldValue(fields, 'body')

  return (
    <div className="full-width" style={{ backgroundColor: '#ededed', padding: '10px' }}>
      <div className="container">
        <h3>{title}</h3>
        {body && documentToReactComponents(body, embeddedRichTextOptions)}
        {'images' in fields && <BlockImageRow fields={fields} />}
      </div>
    </div>
  )
}

export default BlockBackgroundColour
