import React from 'react'
import { getFieldValue } from '../../shared/utilities'

const BlockButton = ({ fields }) => {
  const text = getFieldValue(fields, 'text')
  const link = getFieldValue(fields, 'link')
  const btnClass = getFieldValue(fields, 'type') === 'Primary' ? 'btn-primary' : 'btn-secondary'

  return (
    <div>
      <a href={link} className={`btn ${btnClass}`}>
        {text}
      </a>
    </div>
  )
}

export default BlockButton
