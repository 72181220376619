import React from 'react'
import { getFieldValue, getSafe } from '../../shared/utilities'

const BlockImageRow = ({ fields }) => {
  const images = getFieldValue(fields, 'images')

  const FULL_WIDTH = 1004
  const imgWidth = Math.round(FULL_WIDTH / images.length)
  const imgHeight = Math.round(imgWidth * 0.75)
  const renderedImages = images.map(image => {
    const { id } = image.sys
    const imgQuery = `?w=${imgWidth}&h=${imgHeight}&fit=fill`
    const alt = getSafe(() => image.fields.title['en-US'])
    const url = getSafe(() => image.fields.file['en-US'].url)
    const urlImage = url + imgQuery
    return <img key={id} alt={alt} src={urlImage} />
  })
  return <span style={{ display: 'flex', justifyContent: 'space-between' }}>{renderedImages}</span>
}

export default BlockImageRow
