import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Testimonial from './Testimonial'
import TestimonialCarousel from './TestimonialCarousel'
import { getRandomUniqueArray } from '../shared/utilities'

const TestimonialList = ({ type, count = 4, isCarousel = true }) => {
  const [testimonials, setTestimonials] = useState([])

  const query = useStaticQuery(graphql`
    {
      allContentfulTestimonial {
        edges {
          node {
            id
            name
            jobTitle
            category
            organisation
            quote {
              quote
            }
            createdAt(formatString: "MMMM Do YYYY")
            profileImage {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    const data = query.allContentfulTestimonial.edges
    const filteredData = type !== null ? data.filter(item => item.node.category === type) : data

    const testimonialCount = count && count < filteredData.length ? count : filteredData.length
    const showTestimonials = getRandomUniqueArray(testimonialCount, filteredData.length).map(
      item => filteredData[item]
    )
    setTestimonials(showTestimonials)
  }, [])

  if (testimonials.length > 0) {
    if (isCarousel) {
      return <TestimonialCarousel testimonials={testimonials} />
    }
    return testimonials.map(item => <Testimonial key={item.node.id} data={item.node} />)
  }
  return null
}

export default TestimonialList
