import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { getEmbeddedContentfulUrl, getFieldValue } from '../../shared/utilities'
import embeddedRichTextOptions from '../../shared/embeddedRichTextOptions'

const BlockImageTextRow = ({ fields }) => {
  const userImageQuery = getFieldValue(fields, 'imageQuery')
  // const imgQuery = userImageQuery || '?w=240&h=180&fit=thumb'
  const imgQuery = userImageQuery || '?w=480&h=360&fit=thumb'
  const imgSrc = getEmbeddedContentfulUrl(fields, 'image') + imgQuery
  const title = getFieldValue(fields, 'title')
  const body = getFieldValue(fields, 'body')

  return (
    <div
      className="row"
      style={{ paddingTop: '20px', borderTop: '1px solid #ddd', paddingBottom: '20px' }}
    >
      <div className="col-md-3">
        <img style={{ maxWidth: '100%' }} alt="Block" src={imgSrc} />
      </div>
      <div className="col-md-9" style={{ paddingLeft: '30px' }}>
        {title && <h3 style={{ marginTop: '0' }}>{title}</h3>}
        {body && documentToReactComponents(body, embeddedRichTextOptions)}
      </div>
    </div>
  )
}

export default BlockImageTextRow
