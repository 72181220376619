import React from 'react'
import Hyperlink from './Hyperlink'
import { getSafe } from './utilities'

export default {
  renderNode: {
    'asset-hyperlink': node => {
      const { fields } = node.data.target
      if (fields && 'title' in fields && 'file' in fields) {
        const alt = fields.title['en-US']
        const { url } = fields.file['en-US']
        const link = (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {alt}
          </a>
        )

        return link
      }
      return `asset-hyperlink`
    },

    'embedded-entry-inline': node => {
      const { sys } = node.data.target
      const blockID = sys.contentType.sys.id
      return `embedded-entry-inline: ${blockID}`
    },
    hyperlink: node => {
      const url = node.data.uri
      const text = getSafe(() => node.content[0].value)
      const anchor = <Hyperlink url={url} text={text} />
      return anchor
    }
  }
}
