import React from 'react'
import { getFieldValue } from '../../shared/utilities'
import ComparisonTable from '../ComparisonTable'
import TestimonialList from '../TestimonialList'

const BlockReactComponent = ({ fields }) => {
  const ComponentName = getFieldValue(fields, 'componentName')
  const type = getFieldValue(fields, 'type')

  const components = {
    ComparisonTable,
    TestimonialList
  }

  const Component = components[ComponentName]
  if (typeof Component !== 'undefined') {
    return <Component type={type} />
  }
  return null
}

export default BlockReactComponent
